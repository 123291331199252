import React from "react";
import { Helmet } from "react-helmet";

const ReviewUs = () => {
  const reviewUrl = process.env.GOOGLE_REVIEWS_REDIRECT_URL;
  return (
    <Helmet>
      <meta http-equiv="refresh" content={`0;url=${reviewUrl}`} />
    </Helmet>
  );
};

export default ReviewUs;
